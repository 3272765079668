import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import ProjectImage from './project-image'
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from 'react-responsive-carousel'

const ProjectModal = ({ handleModal, project, mode }) => {
    const data = useStaticQuery(graphql`
            query {
                allFile(filter: {extension: {regex: "/(jpg)|(png)|(jpeg)/"}, relativeDirectory: {eq: "projects/gallery"}}) {
                    edges {
                        node {
                            base
                            childImageSharp {
                                fluid {
                                ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        `)

    const projectImgList = (data, mode) => {
        let filteredImg = data.allFile.edges.filter(item => {
            return item.node.base.match(`project-${project.id}`) != null && item.node.base.split('-')[0] === mode
        })

        return filteredImg.map(item => {
            return <ProjectImage key={item.node.base} {...item} mode={mode} />
        })
    }
    return (
        <div className={`portfolio__project-modal portfolio__project-modal--${mode}`}>
            <button className={`project-modal__close-icon project-modal__close-icon--${mode}`} onClick={handleModal}>
                <span></span>
                <span></span>
            </button>
            <div className={`project-modal__project-details-container project-modal__project-details-container--${mode}`}>
                {
                    mode !== 'mobile' ? (
                        <Carousel
                            className={`project-modal__left-div project-modal__left-div--${mode}`}
                            showArrows={true}
                            showThumbs={true}
                        >
                            {projectImgList(data, mode)}
                        </Carousel>
                    ) : (
                        <div className={`project-modal__left-div project-modal__left-div--${mode}`} >
                            {projectImgList(data, mode)}
                        </div>
                    )
                }
                <div className={`project-modal__right-div project-modal__right-div--${mode}`}>
                    <h2 className={`project-modal__project-title project-modal__project-title--${mode}`}>{project.title}</h2>
                    <div className={`project-modal__project-description project-modal__project-description--${mode}`}>
                        <p>{project.description}</p>
                        {project.urlDemo ? (
                            <a className='project-modal__demo-btn' target='_blank' rel="noreferrer" href={project.urlDemo}>Check demo here</a>
                        ) : ''}

                        {project.urlSourceCode ? (
                            <a className='project-modal__code-btn' target='_blank' rel="noreferrer" href={project.urlSourceCode}>Check code here</a>
                        ) : ''}


                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProjectModal