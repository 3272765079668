import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

const ProjectItem = ({ handleModal, projects, mode }) => {
    const data = useStaticQuery(graphql`
        query {
            allFile(filter: {extension: {regex: "/(jpg)|(png)|(jpeg)/"}, relativeDirectory: {eq: "projects"}}) {
                edges {
                  node {
                    base
                    childImageSharp {
                        fluid (maxHeight: 300, quality: 100) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
    `)
    return (
        data.allFile.edges.map(item => {
            return item.node.base === projects.imgName ? (
                <div
                    key={projects.id}
                    id={projects.id}
                    className={`portfolio__project-item portfolio__project-item--${mode}`}
                    onClick={() => { handleModal(projects.id) }}
                    role="presentation"
                >
                    <div className="portfolio__project-image-container">
                        <Img
                            key={item.node.base}
                            fluid={item.node.childImageSharp.fluid}
                            className={`portfolio__project-image portfolio__project-image--${mode}`}
                        />
                    </div>
                    <div className="portfolio__project-image-overlay">
                        <h3 className="project-image-overlay__title">{projects.title}</h3>
                        <p className="project-image-overlay__sub-title">{projects.subTitle}</p>
                    </div>
                </div>
            ) : ''
        })
    )
}

export default ProjectItem
