import { useState } from 'react'


const PROJECTS = [
    {
        id: 4,
        title: 'Gatsby Portfolio',
        subTitle: 'This website',
        description: `Made a portfolio website using Gatsby JS, 
                    the amazing thing about this is the design is mine and I did not use any templates.
                    I also plan to add a blog section in the future.`,
        urlSourceCode: null,
        urlDemo: '/',
        imgName: 'project-4.png',
    },
    {
        id: 1,
        title: 'POS system Web App',
        subTitle: 'A POS system made with Django',
        description: `This project made with Python Django, Bootstrap, and Jquery.
                    It has all the basic features of a POS system like create, read,
                    update, delete, (CRUD) product, categories of product and customer, 
                    also added a feature that show all prices of saved product on the database.`,
        urlSourceCode: 'https://github.com/spklenfung09/CYJ-POS',
        urlDemo: 'https://vimeo.com/459903432',
        imgName: 'project-1.png',
    },
    {
        id: 2,
        title: 'Inventory Management API',
        subTitle: 'An REST API made with Django (not DRF)',
        description: `An API which can be integrated to any django project that
                    needs Inventory Management capibilities.`,
        urlSourceCode: 'https://github.com/spklenfung09/Inventory-Management-API',
        urlDemo: null,
        imgName: 'project-2.png',
    },
    {
        id: 3,
        title: 'Landing Page',
        subTitle: 'Made a major redesign on a clients website',
        description: `Made a major redesign on a clients website using bootstrap and jquery.
      My first Client outsite the country.`,
        urlSourceCode: null,
        urlDemo: 'http://www.parallaxav.com/',
        imgName: 'project-3.png',

    },
]


export default function usePortfolio() {
    const [projectModalCollapsed, setProjectModalCollapsed] = useState(false)
    const [projectModalData, setProjectModalData] = useState('')

    const handleModal = (id = null) => {
        if (id) {
            let data = PROJECTS.filter(item => {
                return item.id === id ? item : ''
            })
            setProjectModalCollapsed(!projectModalCollapsed)
            setProjectModalData(data[0])
        } else {
            setProjectModalCollapsed(false)
        }
    }

    return {
        PROJECTS,
        projectModalCollapsed,
        projectModalData,
        handleModal
    }
}
