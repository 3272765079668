import React from 'react'
import Img from 'gatsby-image'


export default function ProjectImage({ mode, node }) {
    return (
        <Img
            className={`project-modal__project-image-gallery project-modal__project-image-gallery--${mode}`}
            key={node.base}
            fluid={node.childImageSharp.fluid}
        />
    )
}
