import React from 'react'
import { Link } from "gatsby"

// components
import Layout from "../components/layout"
import SEO from "../components/seo"
import ProjectItem from "../components/project-item"
import ProjectModal from "../components/project-modal"
import ContactForm from "../components/contact-form"

// hooks
import useIndex from '../hooks/useIndex'
import usePortfolio from '../hooks/usePortfolio'
import useContact from '../hooks/useContact'
import useAbout from '../hooks/useAbout'

export default function IndexPage() {
  const {
    mode
  } = useIndex()

  const {
    moreInfo,
    setMoreInfo,
    age
  } = useAbout()

  const {
    PROJECTS,
    projectModalCollapsed,
    projectModalData,
    handleModal,
  } = usePortfolio()

  const {
    SOCIALS
  } = useContact()



  return (
    <Layout currentPage="Home" mode={mode}>
      <SEO title="Hilario C. Petalbo, II" />

      {/* Home */}
      <div className={`container__home container__home--${mode}`} id='home'>
        <h1 className={`home__greetings-text home__greetings-text--${mode}`}>Hi, I'm Jong</h1>
        <h4 className={`home__full-name-text home__full-name-text--${mode}`}>Hilario C. Petalbo, II</h4>
        <h4 className={`home__description-text home__description-text--${mode}`}>Fullstack Web Developer</h4>
        <Link to='/#contact'>
          <button className={`home__cta-btn home__cta-btn--${mode}`}>Let's talk</button>
        </Link>
      </div>

      {/* About Me */}
      <div className={`container__about-me container__about-me--${mode}`} id='about'>
        <div className={`about-me__left-div about-me__left-div--${mode}`}>
          <h3 className={`about-me__title-text about-me__title-text--${mode}`}>Programming Languages</h3>
          <p className={`about-me__description-info about-me__description-info--${mode}`}>
            Python, Javascript, Typescript
          </p>
          <h3 className={`about-me__title-text about-me__title-text--${mode}`}>Front-end</h3>
          <p className={`about-me__description-info about-me__description-info--${mode}`}>
            HTML, CSS, SASS
            <br/>ReactJS, JQuery, Bootstrap 5
          </p>

          <h3 className={`about-me__title-text about-me__title-text--${mode}`}>Back-end</h3>
          <p className={`about-me__description-info about-me__description-info--${mode}`}>Django</p>

          <h3 className={`about-me__title-text about-me__title-text--${mode}`}>DevOps</h3>
          <p className={`about-me__description-info about-me__description-info--${mode}`}>
            Docker, AWS, Heroku, Digital Ocean
            <br/>Netlify, CircleCI, Jenkins
          </p>

          <h3 className={`about-me__title-text about-me__title-text--${mode}`}>Version Control</h3>
          <p className={`about-me__description-info about-me__description-info--${mode}`}>
            Git, Github
            <br/>Gitlab, Bitbucket
          </p>

          <h3 className={`about-me__title-text about-me__title-text--${mode}`}>Others</h3>
          <p className={`about-me__description-info about-me__description-info--${mode}`}>
            Ubuntu Linux, Kali Linux, Chrome Dev Tools
            <br/>Gimp, Nmap, Visual Studio Code
            <br/>Android Debug Tools, Android Studio
          </p>
        </div>
        <div className={`about-me__right-div about-me__right-div--${mode}`}>
          <h3 className={`about-me__title-text about-me__title-text--${mode}`}>Personal Info</h3>
          <p className={`about-me__description-info about-me__description-info--${mode}`}>
            My fullname is Hilario C. Petalbo, II, {age} years
            old and lives in the Philippines.
          </p>

          <h3 className={`about-me__title-text about-me__title-text--${mode}`}>Interests</h3>
          <p className={`about-me__description-info about-me__description-info--${mode}`}>
            I'm mostly interested in projects related to Finance, Business, and Ecommerce, but I'm also interested in other things,
            like Cybersecurity, Artificial Intelligence, and Electronics.
          </p>

          <h3 className={`about-me__title-text about-me__title-text--${mode}`}>How I started?</h3>
          <p className={`about-me__description-info about-me__description-info--${mode}`}>
            I first encountered coding in May 2019.
            I started learning a lot of programming languages with no real goal.
            Then I thought about making money, so I learned
            Fullstack Web Development.
          </p>
          <button
            className={`about-me__more-info-btn about-me__more-info-btn--${mode}`}
            onClick={() => { setMoreInfo(!moreInfo) }}
          >{moreInfo ? 'Hide' : 'More about me'}</button>

          {moreInfo ? (
            <div>
              <h3 className={`about-me__title-text about-me__title-text--${mode}`}>Hobbies, Achievements, etc.</h3>
              <p className={`about-me__description-info about-me__description-info--${mode}`}>
                I play the guitar, I do card tricks, and I play chess
              </p>
            </div>
          ) : ('')}
        </div>
      </div>

      {/* Portfolio */}
      <div className={`container__portfolio container__portfolio--${mode}`} id='portfolio'>
        <h1 className={`portfolio__title--${mode}`}>My Projects</h1>
        <div className={`portfolio__container portfolio__container--${mode}`}>
          {PROJECTS.map(item => {
            return <ProjectItem key={item.id} projects={item} mode={mode} handleModal={handleModal} />
          })}
        </div>
      </div>
      {projectModalCollapsed && (<ProjectModal project={projectModalData} mode={mode} handleModal={handleModal} />)}

      {/* Contact */}
      <div className={`container__contact container__contact--${mode}`} id='contact'>
        <div className={`contact__left-div contact__left-div--${mode}`}>
          <h2 className={`contact__title contact__title--${mode}`}>Others</h2>
          <div className={`contact__socials-container contact__socials-container--${mode}`}>
            {
              SOCIALS.map((item, index) => {
                return (
                  <div key={index} className={`contact__social-item contact__social-item--${mode}`}>
                    <a className={`contact__social-link contact__social-link--${mode}`} href={item.url} target="_blank" rel="noreferrer">
                      {item.icon}
                    </a>
                    <p className={`contact__social-info contact__social-info--${mode}`}>{item.info}</p>
                  </div>
                )
              })
            }
          </div>
        </div>
        <div className={`contact__right-div contact__right-div--${mode}`}>
          <h2 className={`contact__title contact__title--${mode}`}>Email me</h2>
          <ContactForm mode={mode} />
        </div>
      </div>
    </Layout >
  )
}