import React from 'react'
import ReCAPTCHA from "react-google-recaptcha";

const ContactForm = ({ mode }) => {
    return (
        <div>
            <form className={`contact__form contact__form--${mode}`} name="contact-form" method="post" data-netlify="true" data-netlify-recaptcha='true' action="/thank-you/">
                <input type="hidden" name="form-name" value="contact-form" />
                <label className={`contact__label contact__label--${mode}`} htmlFor="name">Enter your name</label>
                <input className={`contact__input contact__input--${mode}`} type="text" name="name" id="name" placeholder="Your name" />
                <label className={`contact__label contact__label--${mode}`} htmlFor="email">Enter your email</label>
                <input className={`contact__input contact__input--${mode}`} type="email" name="email" id="email" placeholder="Your email" />

                <label className={`contact__label contact__label--${mode}`} htmlFor="email">Enter your message</label>
                <textarea className={`contact__textarea contact__textarea--${mode}`} type="text" name="email" id="email" placeholder="Your email" />
                <ReCAPTCHA sitekey="6LcXAecZAAAAAG9tHEPnr77yIUJg6VMN96G3IqnB" />
                <input className={`contact__submit contact__submit--${mode}`} type="submit" value="Send" />
            </form>
        </div>
    )
}

export default ContactForm