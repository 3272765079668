import React from 'react'

// Icons
import Facebook from "../images/social-icons/facebook.svg"
import Gmail from "../images/social-icons/gmail.svg"
import Github from "../images/social-icons/github.svg"
import Twitter from "../images/social-icons/twitter.svg"
import Phone from "../images/social-icons/phone.svg"

const SOCIALS = [
    {
        title: 'Facebook',
        url: 'https://web.facebook.com/hilario.petalbo.96',
        icon: <Facebook className={`contact__social-icon`} />,
        info: 'Hilario C. Petalbo, II'
    },
    {
        title: 'Gmail',
        url: 'https://mail.google.com/mail/u/0/?fs=1&to=jhpetalbo@gmail.com&tf=cm',
        icon: <Gmail className={`contact__social-icon`} />,
        info: 'jhpetalbo@gmail.com'
    },
    {
        title: 'Github',
        url: 'https://github.com/nulldot0',
        icon: <Github className={`contact__social-icon`} />,
        info: 'nulldot0'
    },
    {
        title: 'Twitter',
        url: 'https://twitter.com/nulldot0',
        icon: <Twitter className={`contact__social-icon`} />,
        info: '@nulldot0'
    },
    {
        title: 'Phone',
        url: 'tel:+639631002339',
        icon: <Phone className={`contact__social-icon`} />,
        info: '+63 9631 002 339'
    }
]


export default function useContact() {
    return {
        SOCIALS,
    }
}