import { useState, useEffect } from 'react';

export default function useAbout() {
    const [moreInfo, setMoreInfo] = useState(false)
    const [age, setAge] = useState(0)

    useEffect(() => {
        const birthday = new Date(2000, 9, 20) // October 20th, 2000
        const currentDate = new Date()
        const age = Math.floor((currentDate - birthday) / (1000 * 60 * 60 * 24 * 365))
        
        setAge(age)
    }, [])

    return {
        moreInfo,
        age,

        setMoreInfo
    }
}